import { Injectable } from "@angular/core";
import {Location} from "@angular/common";
import {BehaviorSubject, filter} from "rxjs";
import {NavigationEnd, Router} from "@angular/router";
import {MatDialog, MatDialogConfig} from "@angular/material/dialog";
import {NewPostDialogComponent} from "../post/new-post-dialog.component";
import {GameOwnershipDialogComponent} from "../game/game-ownership-dialog.component";
import {DialogService} from "./ui/dialog.service";
import {NavigationService} from "./ui/navigation.service";
import {SharedDialogsService} from "./shared-dialogs.service";
import {AuthedUserService} from "./authed-user.service";
import {CurrentViewDataService} from "./current-view-data.service";

@Injectable()
export class ContextActionsService {

    startPlayAction: ContextAction = {
        name: $localize`Start Play`,
        icon: "play_arrow",
        action: () => {
            this.addPlay('play')
        },
    }
    logPlayAction: ContextAction = {
        name: $localize`Log Play`,
        icon: "playlist_add",
        action: () => {
            this.addPlay('log')
        },
    }
    addGameAction: ContextAction = {
        name: $localize`Add Game`,
        icon: "library_add",
        action: () => {
            this.addGame()
        },
    }
    postAction: ContextAction = {
        name: $localize`Post`,
        icon: "messages",
        action: () => {
            this.addPost()
        },
    }
    newMeetupAction: ContextAction = {
        name: $localize`Organize Meetup`,
        icon: "event",
        action: () => {
            this.organizeMeetup()
        },
    }
    newPlannedPlayAction: ContextAction = {
        name: $localize`Organize Play`,
        icon: "schedule_send",
        action: () => {
            this.organizePlannedPlay()
        },
    }
    newGroupAction: ContextAction = {
        name: $localize`New Group`,
        icon: "group_add",
        action: () => {
            this.createGroup()
        },
    }

    defaultActions: ContextActions = {
        icon: "add",
        title: $localize`New Play`,
        actions: [
            this.startPlayAction,
            this.logPlayAction,
            this.addGameAction,
            this.postAction,
            //this.newMeetupAction,
            //this.newPlannedPlayAction,
            //this.newGroupAction
        ]
    }
    public actions: ContextActions = this.defaultActions
    public actionsObservable = new BehaviorSubject<ContextActions>(this.actions)

    constructor(
        router: Router,
        location: Location,
        private currentViewDataService: CurrentViewDataService,
        private authedUserService: AuthedUserService,
        private navigationService: NavigationService,
        private dialog: MatDialog,
        private dialogService: DialogService,
        private sharedDialogsService: SharedDialogsService
    ) {
        location.onUrlChange((uri, state) => {
            console.log("URI: ", uri)
            this.updateActions(uri)
        })
        this.currentViewDataService.selectedPlayObservable.subscribe(_ => {
            this.updateActions(location.path())
        })
        this.updateActions(location.path())
    }

    updateActions(uri: string) {
        const selectedPlay = this.currentViewDataService.selectedPlay
        if (selectedPlay) {
            this.actions = {
                icon: "replay",
                title: $localize`Repeat Play`,
                actions: [
                    this.startPlayAction,
                    this.logPlayAction,
                ],
                hide: selectedPlay.state != 'FINISHED'
            }
        }
        else if (uri.startsWith('/g/')) {
            this.actions = {
                icon: "play_arrow",
                title: $localize`New Play`,
                actions: [
                    this.startPlayAction,
                    this.logPlayAction,
                    this.addGameAction,
                ]
            }
        } else if (uri.includes('/collection')) {
            this.actions = {
                icon: "library_add",
                title: $localize`Add Game`,
                actions: [
                    this.addGameAction,
                ]
            }
        }
        else if (uri.includes('/plays')) {
            this.actions = {
                icon: "play_arrow",
                title: $localize`New Play`,
                actions: [
                    this.startPlayAction,
                    this.logPlayAction
                ]
            }
        }
        else if (uri.includes('/activity')) {
            this.actions = {
                icon: "messages",
                title: $localize`Post`,
                actions: [
                    this.postAction
                ]
            }
        }
        else if (uri.startsWith('/p/')) {
            this.actions = {
                icon: "play_arrow",
                title: $localize`New Play`,
                actions: [
                    this.startPlayAction,
                    this.logPlayAction
                ]
            }
        } else if (uri.startsWith('/meetups')) {
            this.actions = {
                icon: "event",
                title: $localize`Nueva Quedada`,
                actions: [
                    this.newMeetupAction,
                    this.newPlannedPlayAction
                ]
            }
        } else if (uri.startsWith('/groups')) {
            this.actions = {
                icon: "group",
                title: $localize`New Group`,
                actions: [
                    this.newGroupAction
                ]
            }
        } else {
            this.actions = this.defaultActions
        }
        this.actionsObservable.next(this.actions)
    }

    addPost() {
        let dialogConfig: MatDialogConfig = {
            data: {},
            minWidth: '550px',
            disableClose: true
        }
        this.dialogService.fullScreenIfMobile(dialogConfig)
        const dialog = this.dialog.open(NewPostDialogComponent, dialogConfig)
        this.dialogService.onDialogOpened(dialog)
        dialog.afterClosed().subscribe(post => {
            if (post) {
                this.navigationService.navigate(['post', post.id], true)
            }
        })
    }

    addPlay(mode: 'log' | 'play') {
        this.sharedDialogsService.openCreatePlay(mode).subscribe(play => {
            if (play) {
                this.navigationService.navigate(['p', play.id])
            }
        })
    }

    addGame() {
        let dialogConfig: MatDialogConfig = {
            minWidth: '550px',
            disableClose: true
        }
        this.dialogService.fullScreenIfMobile(dialogConfig)
        const dialog = this.dialog.open(GameOwnershipDialogComponent, dialogConfig)
        this.dialogService.onDialogOpened(dialog)
        dialog.afterClosed().subscribe((data) => {
            if (data) {
                this.navigationService.navigate([this.authedUserService.getUsername(), 'collection'], true)
            }
        })
    }

    organizeMeetup() {
        this.sharedDialogsService.openMeetupEdit().subscribe(id => {
            if (id) {
                this.navigationService.navigate(['meetups', id])
            }
        })
    }

    organizePlannedPlay() {
        this.sharedDialogsService.openPlannedPlayEdit().subscribe(id => {
            if (id) {
                this.navigationService.navigate(['tables', id])
            }
        })
    }

    createGroup() {
        this.sharedDialogsService.openGroupEdit().subscribe(id => {
            if (id) {
                this.navigationService.navigate(['groups', id])
            }
        })
    }
}

export type ContextActions = {
    icon: string
    title: string
    actions: ContextAction[]
    hide?: boolean
}

export type ContextAction = {
    name: string
    icon: string
    action: () => void
}
